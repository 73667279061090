import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import Message from './Message';
import InputField from './InputField';
import Header from './Header'; 
import Pill from '../Pill/Pill';
import { ChatbotContext } from '../../context/ChatbotContext';

import './ChatWindow.css';
import Loading from '../Loading/Loading';

function ChatWindow() {

    const [messages, setMessages] = useState<{user: string, text: string}[]>([]);
    const [loading, setLoading] = useState(false);
    const [currentStreamMessage, setCurrentStreamMessage] = useState<string>(''); // State to hold the current streaming message

    const assistant = useContext(ChatbotContext);
        
    const sendMessage = async (text: string) => {
        setLoading(true);
        setMessages(messages => [...messages, { user: "User", text }]);
        const session_id = localStorage.getItem('session_id');
        const response = await fetch("/api/chat-stream", {
            method: "POST",
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${session_id}` },
            body: JSON.stringify({ message: text }),
            credentials: "include",
        });

        const reader = response?.body?.getReader();
        const decoder = new TextDecoder("utf-8");

        if (!reader) {
            console.log('no reader found');
            setLoading(false);
            return;
        }

        let buffer = '';
        let completeMessage = ''; // Variable to accumulate the complete message
        let firstDataReceived = false;

        while (true) {
           
            const { done, value } = await reader.read();
            if (done) break;

            buffer += decoder.decode(value, { stream: true });

            let lines = buffer.split('\n');
            //@ts-ignore
            buffer = lines.pop(); // Save the last line as it might be incomplete

            for (let line of lines) {
                if (line.startsWith('data: ')) {
                    const subString = line.substring(6); // Remove 'data: ' prefix
                    completeMessage += subString
                    setCurrentStreamMessage(completeMessage);

                    if (!firstDataReceived) {
                        setLoading(false);
                        firstDataReceived = true;
                    }
                }
            }
        }


        // Once the stream is complete, add the complete message to the messages array
        setMessages(prev => [...prev, { user: "Assistant", text: completeMessage}]);
        setCurrentStreamMessage(''); // Clear the current streaming message
        setLoading(false);
    };

    // Function to handle resetting the chat window
    const resetChat = () => {
        setMessages(
            assistant.preloaded_messages.map((message: string) => {
                return { user: "Assistant", text: message }
            })
        );
    };

    useEffect(() => {
        if (!assistant) return;
        setMessages(
            assistant.preloaded_messages.map((message: string) => {
                return { user: "Assistant", text: message }
            })
        );
    }, [assistant]);

    const bottomRef = useRef<HTMLDivElement>(null);
    
    const scrollToBottom = useCallback(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [messages, currentStreamMessage, scrollToBottom]); // Scroll when messages or currentStreamMessage change


    const handleCloseChatbot = () => {
        try {
            window.parent.postMessage("closeChatbot", "*");
        } catch (error) {
            console.error("Error sending message to parent:", error);
        }
    }

    return (
        <div className="chat-window">
            <Header onResetChat={resetChat} onExitChat={handleCloseChatbot} />
            <div className="messages-list">
                {messages.map((message, index) => (
                    <Message key={index} user={message.user} text={message.text} />
                ))}
                {currentStreamMessage && (
                    <Message user="Assistant" text={currentStreamMessage} />
                )}
                {loading &&
                <div>
                    <Loading />
                </div>
                }   
                <div ref={bottomRef}  />
            </div>
            
            <div className='inputs-container'>
                <div className='suggestions'>
                    {
                        assistant.suggested_prompts.map((prompt, index) => (
                            <Pill key={index} text={prompt} onClick={() => sendMessage(prompt)} disabled={loading} />
                        ))
                    }
                </div>
                <InputField onSendMessage={sendMessage} disabled={loading} />
                <div className='powered-by'>
                    <span><a href='https://aiintegrations.tech/' target='_blank' rel="noreferrer">Powered by AI Integrations</a></span>
                </div>
            </div>
        </div>
    );
}

export default ChatWindow;