// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    background-color: #ffffff;
    border-bottom: 1px solid #f3f3f2;
    margin-left: 1rem;
    position: sticky;
    top: 0;
    z-index: 1000; 
}

.chat-header h2 {
    margin: 0;
    flex-grow: 1;
    color: #3f3f46;
}

.buttons-container {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}

button {
    padding: 5px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background: none;
}

.image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: .5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ChatWindow/Header.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,yBAAyB;IACzB,gCAAgC;IAChC,iBAAiB;IACjB,gBAAgB;IAChB,MAAM;IACN,aAAa;AACjB;;AAEA;IACI,SAAS;IACT,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,yBAAyB;IACzB,QAAQ;AACZ;;AAEA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".chat-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 10px 0;\n    background-color: #ffffff;\n    border-bottom: 1px solid #f3f3f2;\n    margin-left: 1rem;\n    position: sticky;\n    top: 0;\n    z-index: 1000; \n}\n\n.chat-header h2 {\n    margin: 0;\n    flex-grow: 1;\n    color: #3f3f46;\n}\n\n.buttons-container {\n    display: flex;\n    justify-content: flex-end;\n    gap: 5px;\n}\n\nbutton {\n    padding: 5px;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    background: none;\n}\n\n.image {\n    width: 40px;\n    height: 40px;\n    border-radius: 50%;\n    margin-right: .5rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
