// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.message {
    margin: 0.2rem 0;
    padding: 0.5rem 1rem; /* Consistent horizontal padding for better readability */
    border-radius: 10px; /* Rounded corners like iMessage */
    max-width: 75%; /* Width adjustment for resemblance to iMessage */
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    word-wrap: break-word;
    position: relative; /* For additional styling flexibility */
}

.user-message {
    align-self: flex-end;
    background-color: #007aff; /* iMessage blue */
    color: white;
    margin-right: .5rem;
}

.assistant-message {
    align-self: flex-start;
    background-color: #e5e5ea; /* iMessage light gray */
    color: black;
}

.message-text {
    margin-top: 0.25rem; /* Margin-top using 'rem' for consistency */
    margin-bottom: .25rem; /* Margin-bottom */
    font-size: 1rem; /* Slightly larger font for readability */
    line-height: 1.5rem;  /* Line height for readability */

    a {
        text-decoration: underline;
        color: inherit;
        font-weight: 550;
    }

}


`, "",{"version":3,"sources":["webpack://./src/components/ChatWindow/Message.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,oBAAoB,EAAE,yDAAyD;IAC/E,mBAAmB,EAAE,kCAAkC;IACvD,cAAc,EAAE,iDAAiD;IACjE,wCAAwC,EAAE,4BAA4B;IACtE,qBAAqB;IACrB,kBAAkB,EAAE,uCAAuC;AAC/D;;AAEA;IACI,oBAAoB;IACpB,yBAAyB,EAAE,kBAAkB;IAC7C,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,yBAAyB,EAAE,wBAAwB;IACnD,YAAY;AAChB;;AAEA;IACI,mBAAmB,EAAE,2CAA2C;IAChE,qBAAqB,EAAE,kBAAkB;IACzC,eAAe,EAAE,yCAAyC;IAC1D,mBAAmB,GAAG,gCAAgC;;IAEtD;QACI,0BAA0B;QAC1B,cAAc;QACd,gBAAgB;IACpB;;AAEJ","sourcesContent":[".message {\n    margin: 0.2rem 0;\n    padding: 0.5rem 1rem; /* Consistent horizontal padding for better readability */\n    border-radius: 10px; /* Rounded corners like iMessage */\n    max-width: 75%; /* Width adjustment for resemblance to iMessage */\n    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */\n    word-wrap: break-word;\n    position: relative; /* For additional styling flexibility */\n}\n\n.user-message {\n    align-self: flex-end;\n    background-color: #007aff; /* iMessage blue */\n    color: white;\n    margin-right: .5rem;\n}\n\n.assistant-message {\n    align-self: flex-start;\n    background-color: #e5e5ea; /* iMessage light gray */\n    color: black;\n}\n\n.message-text {\n    margin-top: 0.25rem; /* Margin-top using 'rem' for consistency */\n    margin-bottom: .25rem; /* Margin-bottom */\n    font-size: 1rem; /* Slightly larger font for readability */\n    line-height: 1.5rem;  /* Line height for readability */\n\n    a {\n        text-decoration: underline;\n        color: inherit;\n        font-weight: 550;\n    }\n\n}\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
