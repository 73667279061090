import React from 'react';

export default function makeLinksClickable(textArray: string[]) {
  const urlRegex = /(https?:\/\/[^\s]+[^.,;!?)\]}\s])|(\bwww\.[^\s]+[^.,;!?)\]}\s])/g;
  const emailRegex = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
  const boldRegex = /\*\*(.*?)\*\*/g;
  const boldListRegex = /-\s\*\*(.*?)\*\*/g;
  const numberedSentenceRegex = /(\d+\.\s)/g;
  const headingRegex = /###\s([^\n]+)/g;

  // Combine all regexes with the OR operator
  const combinedRegex = new RegExp(
    `(${urlRegex.source})|(${emailRegex.source})|(${boldRegex.source})|(${numberedSentenceRegex.source})|(${headingRegex.source})|(${boldListRegex.source})`,
    'gm'
  );

  return textArray.map((text, arrayIndex) => {
    const parts = [];
    let lastIndex = 0;
    let match;

    const IS_LIST_ITEM = text.startsWith(' -');

    const IS_NUMBERED_SENTENCE = text.match(numberedSentenceRegex);

    while ((match = combinedRegex.exec(text)) !== null) {
      if (lastIndex !== match.index) {
        parts.push(text.slice(lastIndex, match.index));
      }
      parts.push(match[0]);
      lastIndex = combinedRegex.lastIndex;
    }

    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex));
    }


    return (
      <React.Fragment key={`text-${arrayIndex}`}>
        <span style={{
          marginLeft: IS_LIST_ITEM ? '1.5rem' : '0',
          marginBottom: IS_NUMBERED_SENTENCE ? '0.5rem' : IS_LIST_ITEM ? '0.5rem' : '0',
          marginTop: IS_NUMBERED_SENTENCE ? '0.5rem' : IS_LIST_ITEM ? '0.5rem' : '0'
        }}>
        {parts.map((part: string, index) => {
          if (part.match(emailRegex)) {
            return (
              <a key={`${arrayIndex}-${index}`} href={`mailto:${part}`}>
                {part}
              </a>
            );
          }
          if (part.match(urlRegex)) {
            let href = part;
            if (part.startsWith('www.') || part.includes('.com')) {
              href = part;
            }
            return (
              <a key={`${arrayIndex}-${index}`} href={href} target="_blank" rel="noopener noreferrer">
                {part}
              </a>
            );
          }
          
          if (part.match(boldListRegex)) {
            const boldText = part.replace(boldListRegex, '$1');
            return (
              <React.Fragment key={`${arrayIndex}-${index}`}>
                {boldText}
              </React.Fragment>
            );
          }

          if (part.match(boldRegex)) {
            const boldText = part.replace(boldRegex, '$1');
            return (
              <React.Fragment key={`${arrayIndex}-${index}`}>
                <strong>{boldText}</strong>
              </React.Fragment>
            );
          }
          
          if (part.match(numberedSentenceRegex)) {
            return (
              <React.Fragment key={`${arrayIndex}-${index}`}>
                <span>{part}</span>{" "}
              </React.Fragment>
            );
          }
          
          if (part.match(headingRegex)) {
            const headingText = part.replace(headingRegex, '$1');
            return<>
            <br/>
            <strong key={`${arrayIndex}-${index}`}>{headingText}</strong>
            </>;
          }
          return part;
        })}
        {arrayIndex <= textArray.length - 1 && <br />} 
        </span>
        
      </React.Fragment>
    );
  });
}



function extractLines(input: string): string[] {
  // Split the input text by page break delimiter
  const pageBreakPattern = /\f/;
  const pages = input.split(pageBreakPattern);

  // Initialize an array to hold the lines
  const lines: string[] = [];

  // Iterate over each page and split it into lines
  pages.forEach(page => {
    // Split the page into lines based on line breaks
    const pageLines = page.split(/\r?\n/);
    lines.push(...pageLines);
  });

  return lines;
}

function addNewLines(text: string): string {
  // Regular expression to match numbers at the start of a line or after a space,
  // or hyphens preceded by a space, but not within phone numbers
  const regex = /(?<!\(\d{1,3}\)\s?)(?<!\d{3}[-\s]?)(\d+\.\s|\s-\s)/g;

  // Replace matches with a newline followed by the original match
  return text.replace(regex, '\n$1');
}


export const formatText = (text: string) => {
  const newLinesText = addNewLines(text);
  const extractedLines = extractLines(newLinesText);
  const regexedText= makeLinksClickable(extractedLines);
  
  return regexedText;
}