// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.pill-container {
    background-color: #f1f1f3;
    border-radius: 7.5px;
    padding: .5rem .75rem;
    cursor: pointer;
    font-size: medium;
    transition: filter 0.3s ease;
}

.pill-container:hover {
    filter: brightness(95%);
}`, "",{"version":3,"sources":["webpack://./src/components/Pill/Pill.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,oBAAoB;IACpB,qBAAqB;IACrB,eAAe;IACf,iBAAiB;IACjB,4BAA4B;AAChC;;AAEA;IACI,uBAAuB;AAC3B","sourcesContent":["\n.pill-container {\n    background-color: #f1f1f3;\n    border-radius: 7.5px;\n    padding: .5rem .75rem;\n    cursor: pointer;\n    font-size: medium;\n    transition: filter 0.3s ease;\n}\n\n.pill-container:hover {\n    filter: brightness(95%);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
