// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
  font-family: 'Inter', sans-serif;
  font-weight: 425;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;

}

#root {
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.App {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,aAAa;;AAEf;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,YAAY;AACd","sourcesContent":["body, html {\n  font-family: 'Inter', sans-serif;\n  font-weight: 425;\n}\n\nbody {\n  margin: 0;\n  padding: 0;\n  height: 100vh;\n\n}\n\n#root {\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.App {\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
