import React, {useContext} from 'react';
import './Header.css';
import CloseIcon from '../../assets/svgs/CloseIcon';
import RefreshIcon from '../../assets/svgs/RefreshIcon';

import { ChatbotContext } from '../../context/ChatbotContext';

interface HeaderProps {
    onResetChat: () => void;
    onExitChat?: () => void;
}

const Header = ({ onResetChat, onExitChat }: HeaderProps) => {
    const assistant = useContext(ChatbotContext);
   
    return (
        <div className="chat-header">
            <img src={assistant.image_url} alt={assistant.name} className='image' />
            <h2>{assistant.name}</h2>
            <div className="buttons-container">
                <button title="Refresh" onClick={onResetChat}>
                    <RefreshIcon />
                </button>
                <button onClick={onExitChat} aria-label="Exit Chat">
                    <CloseIcon />
                </button>
            </div>
        </div>
    );
};

export default Header;
