// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ChatWindow.css */
.chat-window {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;

}

.messages-list {
    overflow-y: auto; /* Allow scrolling for overflow content */
    display: flex;
    flex-direction: column;
    gap: 4px; /* Reduced gap between messages */
    margin: 1rem .5rem ; /* Adjusted margin for better spacing */
    height: 100%;
}

.suggestions {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: .5rem;
    white-space: nowrap;
    padding: 0 1rem 1rem;
}

.inputs-container {
    position: sticky;
    bottom: 0;
    z-index: 1000; 
    background-color: #ffffff;
}

.powered-by {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    padding: .5rem;
    font-size: .75rem;
    
    a {
        text-decoration: none;
        color: #3f3f46;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/ChatWindow/ChatWindow.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,aAAa;IACb,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,sBAAsB;;AAE1B;;AAEA;IACI,gBAAgB,EAAE,yCAAyC;IAC3D,aAAa;IACb,sBAAsB;IACtB,QAAQ,EAAE,iCAAiC;IAC3C,mBAAmB,EAAE,uCAAuC;IAC5D,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,UAAU;IACV,mBAAmB;IACnB,oBAAoB;AACxB;;AAEA;IACI,gBAAgB;IAChB,SAAS;IACT,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU;IACV,cAAc;IACd,iBAAiB;;IAEjB;QACI,qBAAqB;QACrB,cAAc;IAClB;AACJ","sourcesContent":["/* ChatWindow.css */\n.chat-window {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    justify-content: space-between;\n    flex-direction: column;\n\n}\n\n.messages-list {\n    overflow-y: auto; /* Allow scrolling for overflow content */\n    display: flex;\n    flex-direction: column;\n    gap: 4px; /* Reduced gap between messages */\n    margin: 1rem .5rem ; /* Adjusted margin for better spacing */\n    height: 100%;\n}\n\n.suggestions {\n    display: flex;\n    flex-direction: row;\n    overflow-x: auto;\n    gap: .5rem;\n    white-space: nowrap;\n    padding: 0 1rem 1rem;\n}\n\n.inputs-container {\n    position: sticky;\n    bottom: 0;\n    z-index: 1000; \n    background-color: #ffffff;\n}\n\n.powered-by {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: .5rem;\n    padding: .5rem;\n    font-size: .75rem;\n    \n    a {\n        text-decoration: none;\n        color: #3f3f46;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
