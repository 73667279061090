import React, { useContext, useState, useRef, useEffect } from 'react';
import { ChatbotContext } from '../../context/ChatbotContext';
import MessageIcon from '../../assets/svgs/MessageIcon';
import ChevronDown from '../../assets/svgs/ChevronDown';
import './Chatbot.css';

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const assistant = useContext(ChatbotContext);
  const iframeRef = useRef(null);

  const domain = process.env.NODE_ENV === 'development' ? 'http://localhost:3000' : 'https://aii-chatfull-riij5.ondigitalocean.app';


  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.origin !== domain) return;
      if (event.data === "closeChatbot") {
        setIsOpen(false);
      } else if (event.data === "openChatbot") {
        setIsOpen(true);
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [domain]);

  const dynamicStyle = {
    backgroundImage: (assistant.image_url && !isOpen) ? `url(${assistant.image_url})` : 'none',
    backgroundColor: assistant.color ? assistant.color : 'none'
  };


  return (
    <div className="chatbot-container">
      <div className={`chatbot-window ${isOpen ? 'open' : 'closed'}`}>
        <iframe 
          ref={iframeRef}
          id="chatbot-iframe"
          title='chatbot-iframe' 
          src={`${domain}/full/${assistant.slug}`} 
          style={{ display: isOpen ? 'block' : 'none', border: 'none' }}
        >
        </iframe>
      </div>
      <button className="chatbot-bubble" style={dynamicStyle} onClick={toggleChat} >
        {!assistant.image_url && <MessageIcon />}
        {isOpen && <ChevronDown />}
      </button>
    </div>
  );
};

export default Chatbot;
